
import { useCallback, useState } from "react";
import { useEffect } from "react";
import openArrow from "../../Assets/arrowOpen.svg";
import closeArrow from "../../Assets/arrowClose.svg";
import { useLocation, useNavigate } from "react-router-dom";
import logoutIcon from "../../Assets/logoutIcon.svg";
import { utils } from "../../Common/Utills";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/sessionSlice";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import IntrahubLogo from '../../Assets/IntraHub_Logo.svg';

function MenuList(props) {
  let { userProfileData, menuData } = props;
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [userDetails, setUserDetails] = useState(userProfileData);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname.includes(path) ? "active" : "";
  };

  const isActiveOnly = (path) => {
    return location.pathname === path ? "active" : "";
  };

  useEffect(()=>{
    const updateDimension = () => {
        setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
  },[screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const toggleSubMenu = (index) => {
    if (openSubMenu === index) {
      setOpenSubMenu(null);
    } else {
      setOpenSubMenu(index);
    }
  };

  useEffect(() => {
    if (userProfileData) {
      setUserDetails(userProfileData);
    }
  }, [userProfileData]);

  useEffect(() => {
    // Check if any link inside the submenu is active
    const isSubmenuActive = (submenu) => {
      return submenu.some((item) => {
        return isActive(item.link);
      });
    };

    // Iterate through menuData and find the submenu to expand
    for (const item of menuData) {
      if (item.subMenu) {
        if (isSubmenuActive(item?.subMenu)) {
          setOpenSubMenu(menuData.findIndex((data) => data === item));
        }
      }
    }

    // If no submenu is active, collapse all submenus
    // setOpenSubMenu(null);
  }, [location.pathname, menuData]);

  const Userlogout = async () => {
    await utils.clearAllData();
    dispatch(logout(""));
    navigate("/");
  };

  const checkAccess = async (submenu = []) =>{
    let filterValue = submenu.filter((permission) =>{ return permission?.access?.view === "1" });
    if (filterValue.length > 0) {
      return
    }
    return filterValue
  }

  const handleLinkClick = async (link) => {
    navigate(link);
    setOpenSubMenu(null);
  }
  
  const handleSubLinkClick = async (link) => {
    navigate(link);
  }

  const renderSubMenu = (subMenu, index) => {
    return (
      <ul
        className={`ml-2 mb-2 sub-menu ${openSubMenu === index ? "open" : ""}`}
      >
        {subMenu.map((item, subIndex) => (
          item.access.view === "1" ?
          <li key={subIndex} className="flex items-center px-2">
            <a
              onClick={()=>{handleSubLinkClick(item.link)}}
              className={`sub-menu-label ${isActive(item.link)}`}
            >
              {isActive(item.link) == "active" ? (
                <span className="dot">•</span>
              ) : null}
              {item.label}
            </a>
          </li>
          :null
        ))}
      </ul>
    );
  };

  return (
    <>
      <div className=" ml-2 md:ml-4 mr-2 md:mr-4 my-2 md:my-5 rounded-3xl h-100 sideBarBG relative pt-1">
        
        <div className="ProfileSection mx-4 mt-2 pr-2 pl-2 md:pl-6  py-2 md:py-4 rounded-xl flex items-center">
          <div className="ProfilePicture mr-2">
            <img className="sideProfilePic" src={userDetails.imageURL} />
          </div>
          <div className="ProfileDetails text-white">
            <h3 className="ProfileName">{userDetails.displayName}</h3>
            {/* <h3 className="ProfileDesignation">Chief of Project Delivery</h3> */}
          </div>
        </div>

        <div className="menuSection px-0 md:px-3 py-3 overflow-y-auto" style={{height: screenSize.width < 768 ? '259px' : screenSize.height - 350 + 'px' }}>
          <h5 className="labelHeadeing" >MAIN MENU</h5>
          <div
            className="menu"
            
          >
            <ul>
              <li
                  key={"dashboard"}
                  className={`${isActiveOnly("/dashboard")} `}
                >
                  <span
                    className={`flex items-center px-2  ${isActiveOnly("/dashboard")}`}
                  >
                    <HomeOutlinedIcon 
                        sx={{color: isActiveOnly("/dashboard") === 'active' ? '#FF914E': '#FFF', fontSize: '20px'}} 
                    />
                    <a onClick={()=>{handleLinkClick("/dashboard")}}>{"Dashboard"}</a>
                  </span>
              </li>
              { menuData.length ? menuData.map((item, index) => {
                const IconComponent =
                  require(`./../../Common/SVG/${item.icon}.js`).default;
                return (
                  <li
                    key={index}
                    className={`${
                      openSubMenu === index ? "SubMenuOpen" : ""
                    } ${isActive(item.link)} `}
                  >
                    {item.subMenu ? (
                      item.subMenu.filter((permission) =>{ return permission?.access?.view === "1" }).length > 0 ?
                      <>
                        <span
                          className={`menu-label ${
                            openSubMenu === index ? "open" : ""
                          } pr-2`}
                          onClick={() => toggleSubMenu(index)}
                        >
                          <div className="flex items-center px-2">
                            <IconComponent
                              color={openSubMenu === index ? '#FF914E': '#FFF' }
                            />
                            <p className="subMenuText m-0">{item.label} </p>
                          </div>
                          <span
                            className={`arrow ${
                              openSubMenu === index ? "open" : ""
                            }`}
                          >
                            <img
                              src={
                                openSubMenu === index ? openArrow : closeArrow
                              }
                            />
                          </span>
                        </span>
                        {renderSubMenu(item.subMenu, index)}
                      </>
                      : null
                    ) : 
                      
                        item?.access.view === "1" ? 
                            <span
                              className={`flex items-center px-2  ${isActive(item.link)}`}
                            >
                              <IconComponent 
                                  color= {isActive(item.link) === 'active' ? '#FF914E': '#FFF'} 
                              />
                              <a onClick={()=>{handleLinkClick(item.link)}}>{item.label}</a>
                            </span>
                        :null 
                  }
                  </li>
                );
              }) : null}
            </ul>
          </div>
        </div>
        <div className="absolute bottom-0 w-full logoutSection rounded-b-xl">
          <div className="seprator"></div>
          <span className="flex items-center  px-1 md:px-3 pt-3 pb-1">
            <KeyboardDoubleArrowLeftIcon sx={{color: '#fff'}}/>
            <a
              onClick={() => {
                navigate('/');
              }}
              className="cursor-pointer"
            >
              Back To Check In/Out
            </a>
          </span>
          <span className="flex items-center  px-2 md:px-5 py-2 md:py-3">
            <img src={logoutIcon} />
            <a
              onClick={() => {
                Userlogout();
              }}
              className="cursor-pointer"
            >
              Log Out
            </a>
          </span>
          <div className="px-16 pt-2 md:pt-3 cursor-pointer pb-3" onClick={()=>{navigate('/dashboard')}}>
          {/* <h2 className="favcyText dashboardHeading">Favcy</h2>
          <h2 className="InterhubText dashboardHeading">IntraHub</h2> */}
          <img src={IntrahubLogo} height={92} width={196} />
        </div>
        </div>
      </div>
    </>
  );
}

export default MenuList;
