import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Typography,
  createTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  DateCalendar,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatabaseService } from "../../../../Services/DatabaseService";
import SnackBarComponent from "../../../shared/common/SnackBar";
import saveDraftIcon from "../../../../Assets/saveDraftIcon.svg";
import { Check, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const theme = createTheme();

const LeaveForm = ({
  allTypeLeaves = [],
  managerData = {},
  updateLeavesData,
  showAnotherData,
  permanentHolidays
}) => {
  const todayDate = dayjs();
  const [anchorEl, setAnchorEl] = useState(null);
  const spanRef = useRef(null);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    leave_type: "",
    description: "",
    brief_description: "",
    from_date: null,
    to_date: null,
  });

  const [employeeLeaves, setEmployeeLeaves] = useState({available: 0, booked: 0, approved: 0, in_draft: 0})
  const [formErrors, setFormErrors] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState();
  const [selectedformData, setSelectedFormData] = useState();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [halfDayChecked, setHalfDayChecked] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const location = useLocation();

  useEffect(() => {
    let locationArray = location?.pathname?.split("/");
    if (locationArray[locationArray.length-1] !== 'add') {
      setIsLoading(true);
      setIsEdited(true);
      getLeaveData(locationArray[locationArray.length-1]);
    }
  }, []);

  const getLeaveData = async (id) => {
    let leaveResponse = await DatabaseService('GET', "attendance/applied-leave/"+id);
    console.log("leave_response",leaveResponse);
    if(leaveResponse.code === 200){
      let leaveData = leaveResponse?.result?.leaveData;
      if(leaveData?.status === 1){
        setApprovalStatus(1)
      }else if(leaveData?.status === 2) {
        setApprovalStatus(2)
      }
      let formData = {...leaveData, userData: [leaveResponse?.result?.userData]};
      console.log("formData-------------->",formData);
      setSelectedFormData({...leaveData, userData: [leaveResponse?.result?.userData]});
      setIsFormSubmitted(leaveResponse?.result?.isManager);
      if(leaveResponse?.result?.isManager){
        getselectedUserLeaves(leaveResponse?.result?.userData?._id)
      }
      if(leaveData.days === "0.5"){
        setHalfDayChecked(true);
      }
      setFormData({
        leave_type: leaveData.leave_type,
        description: leaveData.description,
        brief_description: leaveData?.brief_description || "",
        from_date: dayjs(leaveData.from_date),
        to_date: dayjs(leaveData.to_date),
      });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const getselectedUserLeaves = async (userId) =>{
    let userLeavesRes = await DatabaseService("GET", "attendance/userLeavesCount?loginId="+userId);
    if(userLeavesRes?.code === 200){
      setEmployeeLeaves(userLeavesRes?.response);
    }
    console.log("userLeavesRes--------------->",userLeavesRes);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInputClick = () => {
    if (spanRef.current) {
      spanRef.current.click();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const validateForm = () => {
    let errors = false;
    if (!formData.leave_type.trim()) {
      errors = true;
    }

    if (!formData.from_date) {
      errors = true;
    }
    if (!formData.to_date) {
      errors = true;
    } else if (
      formData.from_date &&
      formData.to_date &&
      formData.to_date < formData.from_date
    ) {
      errors = true;
    }
    setFormErrors(errors);
    return !errors;
  };

  const handleSubmit = async (event, status) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      // Handle form submission logic here

      let finalData = {
        leave_type: formData.leave_type,
        description: formData.description,
        from_date: new Date(dayjs(formData.from_date).hour(10)),
        to_date: new Date(dayjs(formData.to_date).hour(10)),
        manager: managerData.displayName,
        status: status,
      };

      finalData.days = halfDayChecked ? "0.5" : countDays(formData.from_date, formData.to_date, permanentHolidays.map((holiday)=>{ return holiday.lookup_code_description}));

      if(finalData.days < 0.5){
        alert("Please Select valid dates.");
        return
      }

      if (isEdited) {
        finalData.manager = selectedformData.manager;
        if(isFormSubmitted){
          finalData.brief_description = formData.brief_description;
        }
        let response = await DatabaseService(
          "PUT",
          "attendance/apply-leave/" + selectedformData?._id,
          finalData
        );
        console.log("response-------------->", response);
        if (response.code === 200) {
          updateLeavesData();
        } else {
          setIsSnackBarOpen(true);
          setSnackBarMessage(response.message);
          setTimeout(() => {
            setIsSnackBarOpen(false);
          }, 2000);
        }
      } else {
        let response = await DatabaseService(
          "POST",
          "attendance/apply-leave",
          finalData
        );
        console.log("response-------------->", response);
        if (response.code === 200) {
          updateLeavesData();
        } else {
          setIsSnackBarOpen(true);
          setSnackBarMessage(response.message);
          setTimeout(() => {
            setIsSnackBarOpen(false);
          }, 2000);
        }
      }
    } else {
      console.log("not_valid");
    }
  };

  const onfrom_dateChange = async (value) => {
    console.log("from_date_value", value);
    setFormData({ ...formData, from_date: dayjs(value?.$d), to_date: null });
    setHalfDayChecked(false);
    // setfrom_date(dayjs(value?.$d));
  };
  
  const onto_dateChange = async (value) => {
    console.log("formData----------->", formData);
    setFormData({ ...formData, to_date: dayjs(value?.$d) });
    setHalfDayChecked(false);
  };

  const handleChange = async (e) => {
    console.log("radio_event------------->",e.target.value);
    setApprovalStatus(e.target.value);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const countDays = (fromDate, toDate, holidays) => {
    const startDate = dayjs(fromDate);
    const endDate = dayjs(toDate);
    
    // Parse holidays to dayjs objects for accurate comparison
    const holidayDates = holidays.map(holiday => dayjs(holiday, 'DD-MMM-YYYY'));
    
    let sundayCount = 0;
    let holidayCount = 0;
    
    let currentDate = startDate;

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
        // Check if the current date is a Sunday
        if (currentDate.day() === 0) {
            sundayCount++;
        }

        // Check if the current date is a holiday
        if (holidayDates.some(holiday => currentDate.isSame(holiday, 'day'))) {
            holidayCount++;
        }

        currentDate = currentDate.add(1, 'day');
    }

    // Calculate the total number of days between fromDate and toDate inclusive
    let leaveDays = Math.floor(dayjs(toDate).diff(dayjs(fromDate), "days")) + 1;

    // Subtract the number of Sundays and holidays from the total days
    return leaveDays - sundayCount - holidayCount;
  };

  const handleHalfDayChange = (event) => {
    setHalfDayChecked(event.target.checked);
  }


  return (
    <>
      {
        isLoading ? 
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: screenSize.height - 200,
            }}
          >
            <CircularProgress />
            Loading...
          </Box>
        :
      <form>
        <Box className="flex flex-col justify-between">
          <FormGroup
            className="flex flex-col overflow-y-auto"
            sx={{ height: screenSize.width < 768 ? screenSize.height - 320 + "px" : screenSize.height - 270 + "px" }}
          >
            <div className="grid md:grid-cols-5 md:gap-2 mt-1 items-start w-full">
              <Box className="grid col-span-3 gap-1 md:gap-3">
                {
                  isFormSubmitted ?
                    <div className="grid grid-cols-12 gap-1">
                      <div className="col-span-3">
                        <Typography sx={FormStyles.labelStyle}>
                          Employee Name
                        </Typography>
                        <Typography sx={{pt: "5px", color: "#FF914E", fontSize: "14px", fontWeight: 600}}>
                          {selectedformData?.userData[0]?.displayName}
                        </Typography>
                      </div>
                      <div className="col-span-3">
                        <Typography sx={FormStyles.labelStyle}>
                          Approval Status
                        </Typography>
                        <Typography sx={{pt: "5px", color: selectedformData?.status === 0 ? "#EE8B00": selectedformData?.status === 1 ? "#007C32" : selectedformData?.status === 3 ? "#801F52" : "#FF3434", fontSize: "14px", fontWeight: 600}}>
                          {selectedformData?.status === 0 ? "Pending": selectedformData?.status === 1 ? "Approved" : selectedformData?.status === 3 ? "In-Draft" : "Rejected" }
                        </Typography>
                      </div>
                      <div className="col-span-3">
                        <Typography sx={FormStyles.labelStyle}>
                        Paid Leaves Available
                        </Typography>
                        <Typography sx={{pt: "5px", color: "#801F52", fontSize: "14px", fontWeight: 600}}>
                          {employeeLeaves.available}
                        </Typography>
                      </div>
                      <div className="col-span-3">
                        <Typography sx={FormStyles.labelStyle}>
                          Approved Leaves
                        </Typography>
                        <Typography sx={{pt: "5px", color: "#007C32", fontSize: "14px", fontWeight: 600}}>
                          {employeeLeaves.approved}
                        </Typography>
                      </div>
                    </div>
                  : null
                }
                <div className="grid grid-cols-9 gap-1">
                  <div className="col-span-4">
                    <Typography sx={FormStyles.labelStyle}>
                      Start Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* <DemoContainer components={["DatePicker"]}> */}
                        <DatePicker
                          format="DD-MMM-YYYY"
                          sx={{
                            "& .MuiInputBase-formControl": {
                              border: "1px solid #FF914E",
                              background: "#FF914E14",
                              borderRadius: "12px",
                              boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                              mb: 0.5,
                              color: "#FF914E",
                              fontSize: "14px",
                              width: screenSize.width/5.5,
                            },
                            "& .MuiInputBase-formControl.Mui-disabled": {
                              borderColor: "#FF914E66",
                              background: "#FEDEC9",
                              color: "#FFBB93",
                            },
                            "& .MuiInputBase-adornedEnd:focus": {
                              border: "1px solid #801F52",
                              background: "#FF914E00",
                              borderRadius: "12px",
                              boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                              mb: 0.5,
                              color: "#801F52",
                              fontSize: "14px",
                              width: screenSize.width/5.5,
                            },
                            "& .MuiOutlinedInput-input": {
                              py: "9px",
                            },
                            "& .MuiOutlinedInput-input.Mui-disabled": {
                              "-webkit-text-fill-color": "#FFBB93",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiFormLabel-root": {
                              top: "-7px",
                            },
                            "& .MuiFormLabel-root.MuiInputLabel-shrink": {
                              transform: "translate(14px, -3px) scale(0.75)",
                            },
                            [theme.breakpoints.down("md")]: {
                              "& .MuiInputBase-formControl": {
                                width: "150px",
                              },
                              "& .MuiInputBase-adornedEnd:focus": {
                                width: "150px",
                              },
                            }
                          }}
                          disabled={isFormSubmitted || selectedformData?.status === 0 || selectedformData?.status === 1}
                          value={
                            formData.from_date
                              ? dayjs(formData.from_date)
                              : null
                          }
                          minDate={todayDate && dayjs()}
                          onChange={(value) => onfrom_dateChange(value)}
                        />
                      {/* </DemoContainer> */}
                    </LocalizationProvider>
                  </div>
                  <div className="col-span-4">
                    <Typography sx={FormStyles.labelStyle}>End Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* <DemoContainer components={["DatePicker"]}> */}
                        <DatePicker
                          format="DD-MMM-YYYY"
                          sx={{
                            "& .MuiInputBase-formControl": {
                              border: "1px solid #FF914E",
                              background: "#FF914E14",
                              borderRadius: "12px",
                              boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                              mb: 0.5,
                              color: "#FF914E",
                              fontSize: "14px",
                              width: screenSize.width/5.5,
                            },
                            "& .MuiInputBase-formControl.Mui-disabled": {
                              borderColor: "#FF914E66",
                              background: "#FEDEC9",
                              color: "#FFBB93",
                            },
                            "& .MuiInputBase-adornedEnd:focus": {
                              border: "1px solid #801F52",
                              background: "#FF914E00",
                              borderRadius: "12px",
                              boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                              mb: 0.5,
                              color: "#801F52",
                              fontSize: "14px",
                              width: screenSize.width/5.5,
                            },
                            "& .MuiOutlinedInput-input": {
                              py: "9px",
                            },
                            "& .MuiOutlinedInput-input.Mui-disabled": {
                              "-webkit-text-fill-color": "#FFBB93",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiFormLabel-root": {
                              top: "-7px",
                            },
                            "& .MuiFormLabel-root.MuiInputLabel-shrink": {
                              transform: "translate(14px, -3px) scale(0.75)",
                            },
                            [theme.breakpoints.down("md")]: {
                              "& .MuiInputBase-formControl": {
                                width: "150px",
                              },
                              "& .MuiInputBase-adornedEnd:focus": {
                                width: "150px",
                              },
                            }
                          }}
                          disabled={isFormSubmitted || selectedformData?.status === 0 || selectedformData?.status === 1}
                          minDate={
                            formData.from_date && dayjs(formData.from_date)
                          }
                          value={
                            formData.to_date ? dayjs(formData.to_date) : null
                          }
                          onChange={(value) => {
                            onto_dateChange(value);
                          }}
                        />
                      {/* </DemoContainer> */}
                    </LocalizationProvider>
                  </div>
                  <div className="col-span-1 flex flex-col relative">
                    <Typography sx={FormStyles.labelStyle}>Days</Typography>
                    <Typography
                      sx={{
                        px: 2,
                        fontSize: "14px",
                        border: "1px solid",
                        py: 1,
                        borderRadius: "12px",
                        color: isFormSubmitted  || selectedformData?.status === 0 || selectedformData?.status === 1 ? "#FFBB93":"#FF914E",
                        borderColor: isFormSubmitted  || selectedformData?.status === 0 || selectedformData?.status === 1 ? "#FF914E66" :"#FF914E",
                        background: isFormSubmitted  || selectedformData?.status === 0 || selectedformData?.status === 1? "#FEDEC9" :"#FF914E14",
                      }}
                    >
                      {
                        formData.from_date && formData.to_date && halfDayChecked ? 0.5 :
                        formData.from_date && formData.to_date
                        ? countDays(formData.from_date, formData.to_date, permanentHolidays.map((holiday)=>{ return holiday.lookup_code_description}))
                        : 0}
                    </Typography>
                    {
                      formData.leave_type !== "Work From Home" && selectedformData?.status !== 0 && !isFormSubmitted && formData.from_date && formData.to_date && countDays(formData.from_date, formData.to_date, permanentHolidays.map((holiday)=>{ return holiday.lookup_code_description})) === 1 ? 
                        <FormGroup className="absolute bottom-[-30%] w-[100px]">
                          <FormControlLabel 
                            sx={{
                              m: 0,
                              mr: 1,
                              ".MuiFormControlLabel-label":{
                                fontSize: "14px",
                                color: "#FF914E",
                              }
                            }}
                          control={<Checkbox
                          checked={halfDayChecked}
                          disabled={isFormSubmitted && isEdited}
                          onChange={handleHalfDayChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                            sx={{
                              padding: "0px 1px",
                              pr: "4px",
                              color: "#FF914E",
                              '&.Mui-checked': {
                                color: "#FF914E",
                              },
                              '.MuiSvgIcon-fontSizeMedium':{
                                fontSize: "18px"
                              }
                            }}
                          />} 
                          label="Half Day"
                          />
                        </FormGroup>
                      :null
                    }
                    
                  </div>
                </div>
                <Box className={isFormSubmitted ? 'grid grid-cols-12 gap-1': 'flex flex-col gap-3 w-3/5'}>
                  <Box className={isFormSubmitted ? "grid col-span-5": ""}>
                    <Typography sx={FormStyles.labelStyle}>
                      Approval manager
                    </Typography>
                    <Box
                      className=""
                      sx={{
                        px: 2,
                        fontSize: "14px",
                        border: "1px solid",
                        py: 1,
                        borderRadius: "12px",
                        mt: 1,
                        color: isFormSubmitted || selectedformData?.status === 0 || selectedformData?.status === 1 ? "#FFBB93":"#FF914E",
                        borderColor: isFormSubmitted || selectedformData?.status === 0 || selectedformData?.status === 1 ? "#FF914E66" :"#FF914E",
                        background: isFormSubmitted || selectedformData?.status === 0 || selectedformData?.status === 1? "#FEDEC9" :"#FF914E14",
                        [theme.breakpoints.down("md")]: {
                          minWidth: '150px',
                        },
                      }}
                    >
                      <input
                        type="text"
                        readOnly
                        id={"input-label"}
                        className=" block mr-2 text-sm outline-none bg-transparent"
                        style={FormStyles.inputBox}
                        value={
                          isEdited
                            ? selectedformData.manager
                            : managerData.displayName || ""
                        }
                      />
                    </Box>
                  </Box>
                  <Box className={isFormSubmitted ? " col-span-7": ""}>
                    <Typography sx={FormStyles.labelStyle}>Leave Type</Typography>
                    <Box
                      className="flex"
                      sx={{
                        px: 2,
                        fontSize: "14px",
                        border: "1px solid",
                        py: 1,
                        borderRadius: "12px",
                        mt: 1,
                        color: isFormSubmitted  || selectedformData?.status === 0 || selectedformData?.status === 1 ? "#FFBB93":"#FF914E",
                        borderColor: isFormSubmitted  || selectedformData?.status === 0 || selectedformData?.status === 1 ? "#FF914E66" :"#FF914E",
                        background: isFormSubmitted  || selectedformData?.status === 0 || selectedformData?.status === 1? "#FEDEC9" :"#FF914E14",
                      }}
                    >
                      <input
                        type="text"
                        readOnly
                        id={"input-label"}
                        className="block mr-2 text-sm outline-none flex-grow bg-transparent"
                        style={FormStyles.inputBox}
                        disabled={isFormSubmitted  || selectedformData?.status === 0 || selectedformData?.status === 1}
                        value={formData.leave_type || ""}
                        onClick={handleInputClick}
                      />
                      {
                        isFormSubmitted  || selectedformData?.status === 0 || selectedformData?.status === 1 ? null :
                        <span onClick={handleClick} ref={spanRef}>
                          <KeyboardArrowDownIcon />
                        </span>
                      }
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiPopover-paper": {
                            background: "#FEDEC9",
                            border: "1px solid #801F52",
                            borderRadius: "10px",
                          },
                        }}
                      >
                        {allTypeLeaves && allTypeLeaves.length
                          ? allTypeLeaves.map((leave) => {
                              return (
                                <MenuItem
                                  sx={{
                                    fontSize: "14px",
                                    color: "#801F52",
                                    ":hover": {
                                      bgcolor: "#FFE8DC",
                                      color: "#FF914E",
                                      borderRadius: "10px",
                                    },
                                  }}
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      leave_type: leave.type,
                                    });
                                    setHalfDayChecked(false);
                                    handleClose();
                                  }}
                                  key={leave?._id}
                                >
                                  {leave.type}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Popover>
                    </Box>
                  </Box>
                </Box>
                <Box className="">
                  <Typography sx={FormStyles.labelStyle}>
                    Comment (Optional)
                  </Typography>
                  <Box>
                    <textarea
                      className="w-full rounded-[12px] bg-[#FF914E14] border-[#FF914E] border mt-2 text-[#FF914E] placeholder:text-[#ffc7a7] focus:border-[#801F52] focus:text-[#801F52] disabled:border-[#FF914E66] disabled:bg-[#FEDEC9] disabled:text-[#FFBB93]"
                      placeholder="Please enter your reason for leave.."
                      rows={2}
                      disabled={isFormSubmitted || selectedformData?.status === 0 || selectedformData?.status === 1}
                      value={formData.description}
                      onInput={(e) => {
                        setFormData({
                          ...formData,
                          description: e.target.value,
                        });
                      }}
                      style={{
                        padding: "15px",
                        outline: "none",
                        fontSize: "14px",
                      }}
                    />
                  </Box>
                </Box>
                {isFormSubmitted ? (
                  <Box className="flex">
                    <Box className="" sx={{}}>
                      <Typography
                        sx={
                          isFormSubmitted
                            ? FormStyles.labelStyle
                            : FormStyles.disabledLeftSideLabel
                        }
                      >
                        Approved?
                      </Typography>
                      <div className="">
                        <RadioGroup row disabled 
                          value={approvalStatus}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                sx={FormStyles.wereTheyMet}
                                disabled={selectedformData.status === 1 || selectedformData.status === 2}
                                value="1"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "no" }}
                              />
                            }
                            label="Yes"
                            sx={FormStyles.wereTheyMet}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                sx={FormStyles.wereTheyMet}
                                disabled={selectedformData.status === 1 || selectedformData.status === 2}
                                value="2"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "no" }}
                              />
                            }
                            sx={FormStyles.wereTheyMet}
                            label="No"
                          />
                        </RadioGroup>
                      </div>
                    </Box>
                    <Box className=" flex-grow" sx={{}}>
                      <Typography
                        sx={
                          isFormSubmitted
                            ? FormStyles.labelStyle
                            : FormStyles.disabledLeftSideLabel
                        }
                      >
                        Add Remarks (Optional)
                      </Typography>
                        <textarea
                          className="w-full rounded-[12px] bg-[#FF914E14] border-[#FF914E] border mt-2 text-[#FF914E] placeholder:text-[#ffc7a7] focus:border-[#801F52] focus:text-[#801F52] disabled:border-[#FF914E66] disabled:bg-[#FEDEC9] disabled:text-[#FFBB93]"
                          placeholder="Please enter your remarks.."
                          rows={1}
                          disabled={selectedformData.status === 1 || selectedformData.status === 2}
                          value={formData.brief_description}
                          onInput={(event) => {
                            setFormData({
                              ...formData,
                              brief_description: event.target.value,
                            });
                          }}
                          style={{
                            padding: "15px",
                            outline: "none",
                            fontSize: "14px",
                          }}
                        />
                    </Box>
                  </Box>
                ) : null}
              </Box>
              <Box className="hidden md:grid col-span-2 gap-1 justify-center items-center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateRangeCalendar"]}>
                    <DateCalendar
                      disabled
                      sx={{
                        borderRadius: "16px",
                        width: screenSize.width/3.7,
                        background: "#FFE8DC",
                        border: "1px solid #FF914E",
                        ".MuiPickersCalendarHeader-root": {
                          backgroundColor: "#FF914E",
                          padding: "25px",
                          marginTop: "0px",
                        },
                        ".MuiPickersCalendarHeader-label": {
                          textAlign: "center",
                          color: "#fff",
                        },

                        ".MuiPickersArrowSwitcher-button": {
                          paddingRight: "7px",
                          color: "#fff !important",
                        },
                        ".MuiPickersCalendarHeader-switchViewIcon": {
                          color: "#fff !important",
                        },
                        ".MuiDayCalendar-weekDayLabel": {
                          height: "42px",
                          width: "45px",
                          color: "#595959",
                          fontWeight: 600,
                        },
                        ".MuiPickersDay-dayWithMargin": {
                          height: "42px",
                          width: "45px",
                        },
                        "& .MuiPickersDay-today": {
                          border: "1px solid #FF914E !important",
                          borderRadius: "5px",
                        },
                        "& .MuiPickersDay-root": {
                          "&.Mui-selected": {
                            backgroundColor: "#FF914E",
                            height: "34px",
                            width: "40px",
                            borderRadius: "5px",
                          },
                        },
                        [theme.breakpoints.down("md")]: {
                          height: "290px",
                          ".MuiPickersCalendarHeader-root": {
                            padding: "20px",
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box className="grid col-span-5">
                {formErrors ? (
                  <Typography className="p-0 text-red-500 align-middle text-center w-full">
                    Please fill all fields.
                  </Typography>
                ) : null}
              </Box>
            </div>
          </FormGroup>
          <div className="flex justify-end items-center gap-4">
            {
              isFormSubmitted ? <>

              <Button
                sx={{ px: 1, mt: 2, fontWeight: 600, fontSize: "14px", color: "#801F52" }}
                className="gap-2"
                onClick={(e) => {
                  showAnotherData("previous");
                }}
              >
                <KeyboardArrowLeft />
              {"Previous"}
              </Button>
              <Button
                sx={{ px: 1, mt: 2, fontWeight: 600, fontSize: "14px", color: "#801F52" }}
                className="gap-2"
                onClick={(e) => {
                  showAnotherData("next")
                }}
              >
                {"Next"}
                <KeyboardArrowRight />
              </Button>
              {
                isFormSubmitted && (selectedformData?.status === 1 || selectedformData?.status === 2) ? 
                  <Button
                  sx={{ px: 2, mt: 2, fontWeight: 600, fontSize: "14px", background: "#FF914E", color: "#fff", borderRadius: "50px", ":hover":{ background: "#FF914E", color: "#fff", } }}
                      className="gap-2"
                      onClick={(e) => {
                      }}
                    >
                      Confirmed
                      <Check />
                    </Button>
                :
                <Button
                    sx={{ ...FormStyles.applyBTN, px: 4 }}
                    type="submit"
                    disabled={!approvalStatus}
                    className="gap-2"
                    onClick={(e) => {
                      handleSubmit(e, approvalStatus);
                    }}
                  >
                    Confirm
                  </Button>
              }
              </> : 
              selectedformData?.status === 3 ||
              selectedformData?.status === 1 ||
              selectedformData?.status === 0 ? (
                <Button
                  sx={{ ...FormStyles.cancelBTN, px: 4 }}
                  type="submit"
                  className="gap-2"
                  onClick={(e) => {
                    handleSubmit(e, 4);
                  }}
                >
                  Revoke Request
                </Button>
              ) : (
                <Button
                  sx={FormStyles.cancelBTN}
                  type="submit"
                  className="gap-2"
                  onClick={(e) => {
                    handleSubmit(e, 3);
                  }}
                >
                  Save as Draft
                  <img
                    src={saveDraftIcon}
                    className="-mt-1"
                    alt="save as draft"
                  />
                </Button>
              )
            }
            {selectedformData?.status === 0 ||
            selectedformData?.status === 1 || selectedformData?.status === 2 ? null : (
              <Button
                sx={FormStyles.applyBTN}
                type="submit"
                onClick={(e) => {
                  handleSubmit(e, 0);
                }}
              >
                Submit Request
              </Button>
            )}
          </div>
        </Box>
      </form>
      }
      {isSnackBarOpen ? <SnackBarComponent message={snackBarMessage} /> : null}
    </>
  );
};

export default LeaveForm;

const FormStyles = {
  LeftSideLabel: {
    backgroundColor: "#801F52",
    color: "#ffffff",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  disabledLeftSideLabel: {
    backgroundColor: "#909090",
    color: "#ffffff",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  inputBox: {
    fontSize: "14px",
  },
  wereTheyMet: {
    color: "#FF914E",
    "&.Mui-checked": {
      color: "#FF914E",
    },
    "&.Mui-disabled": {
      color: "#FFBB93",
    },
    "& .MuiFormControlLabel-label.Mui-disabled":{
      color: "#FFBB93",
    }
  },
  applyBTN: {
    boxShadow: "4px 4px 40px 0px #0000000D",
    color: "#FFF",
    backgroundColor: "#801F52",
    padding: "10px 30px 10px 30px",
    borderRadius: "50px",
    fontSize: "14px",
    lineHeight: "19.76px",
    marginRight: "10px",
    fontWeight: 500,
    mt: 2,
    ":hover,:focus": {
      color: "#FFF",
      backgroundColor: "#801F52",
    },
    ":disabled": {
      backgroundColor: "#00000042",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px"
    }
  },
  cancelBTN: {
    color: "#801F52",
    boxShadow: "4px 4px 40px 0px #0000000D",
    border: "1px solid #801F52",
    padding: "10px 20px 10px 20px",
    borderRadius: "50px",
    lineHeight: "19.76px",
    marginRight: "10px",
    fontWeight: 700,
    mt: 2,
    ":hover,:focus": {
      color: "#801F52",
    },
  },
  labelStyle: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#FBC7A4",
  },
};
