import React, { Fragment, useEffect, useState } from "react";
import "../../LookupTypes/LookupTypes.css";
import {
  Avatar,
  Button,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createTheme,
} from "@mui/material";
import dayjs from "dayjs";
import editIconNew from "../../../../Assets/EditIconNew.svg";
const theme = createTheme();

function LeavesDataTable(props) {
  let {
    tableData: data = [],
    pagination = { total_pages : 0 , current_page : 0 },
    showingAll = false,
    handleEditClick,
    handlePageChange,
    userData,
  } = props;
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  useEffect(() => {
    setTableData(data.map((data)=>{ return {employeeName: data.userData[0].displayName, employeeImage: data.userData[0].imageURL, from: dayjs(data.from_date).format("DD-MMM-YYYY"), to: dayjs(data.to_date).format("DD-MMM-YYYY"), ...data } }));
    setLoading(false);
  }, []);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const columns = [
    { id: 'employeeName', label: 'Employee\u00a0Name', minWidth: 80 },
    { id: 'manager', label: 'Reporting\u00a0To', minWidth: 80 },
    {
      id: 'leave_type',
      label: 'Leave\u00a0Type',
      maxWidth: 150
    },
    {
      id: 'from',
      label: 'From',
      minWidth: 80,
      format: (value) => dayjs(value).format("DD-MMM-YYYY"),
    },
    {
      id: 'to',
      label: 'To',
      minWidth: 80,
      format: (value) => dayjs(value).format("DD-MMM-YYYY"),
    },
    {
      id: 'days',
      label: 'Days',
      minWidth: 30
    },
    {
      id: 'status',
      label: 'status',
      minWidth: 80,
    },
  ];

  // Function to determine the color based on status value
const getStatusColor = (status) => {
  switch (status) {
    case 'Pending':
      return '#EE8B00';
    case 'Approved':
      return '#007C32';
    case 'In-Draft':
      return '#801F52';
    default:
      return '#FF3434';
  }
};

  return (
    <div>
      <div
        className="overflow-auto bg-white px-2 flex flex-col justify-between items-center pb-1"
        style={{
          // width:
          //   screenSize.width < 768 ? screenSize.width : screenSize.width - 420,
          height:
            screenSize.width < 768
              ? screenSize.height - 300
              : screenSize.height - 195,
          borderRadius: "16px",
          boxShadow: "4px 4px 20px 0px #0000000D",
        }}
      >

      <TableContainer sx={{ maxHeight: screenSize.width < 768
              ? screenSize.height - 190
              : screenSize.height - 235, mt: 1, borderRadius: "12px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{mb: 1, borderRadius: "12px" }}>
              <TableCell
                key="avatar"
                sx={{
                  // position: 'sticky',
                  // left: 0,
                  // zIndex: 100,
                  minWidth: '30px !important', 
                  backgroundColor: "#801F52",
                  p: "8px !important",
                  borderTopLeftRadius: "12px",
                  borderBottomLeftRadius: "12px",
                }}
              >
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth, color: '#fff', backgroundColor: "#801F52", padding: "8px", fontWeight: 600 }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                key="action"
                sx={{
                  // position: 'sticky',
                  // right: 0,
                  // zIndex: 100,
                  minWidth: '30px !important', 
                  backgroundColor: "#801F52",
                  p: "8px !important",
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              >
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .map((row, index) => {
                return (
                  <Fragment key={row._id} >
                    <TableRow sx={{ height: '10px', backgroundColor: 'transparent' }}>
                      <TableCell colSpan={columns.length + 1} sx={{ borderBottom: 'none', p: 0 }} />
                    </TableRow>
                    <TableRow role="checkbox" tabIndex={-1} key={row._id} sx={{mb: 2, backgroundColor: index % 2 === 0 ? '#FEDEC9' : '#FFEEE4', borderRadius: "12px"}}>
                      <TableCell
                        key="avatar"
                        sx={{
                          position: 'sticky',
                          left: 0,
                          backgroundColor: index % 2 === 0 ? '#FEDEC9' : '#FFEEE4',
                          zIndex: 1,
                          minWidth: "30px !important",
                          p: "8px",
                          borderTopLeftRadius: "12px",
                          borderBottomLeftRadius: "12px",
                        }}
                      >
                        <Avatar alt={row.name} src={row.employeeImage} sx={{ width: 24, height: 24 }} />
                      </TableCell>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} sx={{p: "8px", color: "#221F4B", fontWeight: 500}}>
                            {column.format ? column.format(value) : column.id === 'status' ? (
                              <span style={{ color: getStatusColor(value)}}>
                                {value}
                              </span>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell
                        key="action"
                        sx={{
                          position: 'sticky',
                          right: 0,
                          backgroundColor: index % 2 === 0 ? '#FEDEC9' : '#FFEEE4',
                          zIndex: 1,
                          minWidth: "30px !important",
                          p: "8px",
                          borderTopRightRadius: "12px",
                          borderBottomRightRadius: "12px",
                          [theme.breakpoints.down("md")]: {
                            p: "0px",
                            right: "-2px"
                          },
                          
                        }}
                      >
                        {row.status != "Revoked" && row.status !== "Rejected" && (dayjs(row.from_date).isAfter(dayjs(), 'day') || dayjs(row.from_date).isSame(dayjs(), 'day')) && !showingAll ? (
                        <IconButton
                          aria-label="Edit"
                          sx={{ p: "1px" }}
                          onClick={() => {
                            handleEditClick(row, showingAll);
                          }}
                        >
                          <img src={editIconNew} alt="Edit" />
                        </IconButton>
                      ) : null}
                      {showingAll && row.status != "Revoked" && row.status !== "Rejected" && row.status !== "In-Draft" &&
                      row.manager.toLowerCase() ===
                        userData.displayName.toLowerCase() ? (
                        <Button
                          sx={{
                            p: 0,
                            borderRadius: 2,
                            backgroundColor: "#FFF",
                            color: "#FF914E",
                            "& :hover": {
                              backgroundColor: "#FF914E",
                              color: "#FFF",
                            },
                          }}
                          onClick={() => {
                            handleEditClick(row, showingAll);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "11px",
                              px: "4px",
                              py: "7px",
                              borderRadius: 2,
                              fontWeight: 500,
                            }}
                          >
                            View Status
                          </Typography>
                        </Button>
                      ) : null}
                      </TableCell>
                    </TableRow>
                </Fragment>
                );
              })}
          </TableBody>
        </Table>
        </TableContainer>
        <Pagination
          count={parseInt(pagination?.total_pages) || 0}
          page={parseInt(pagination?.current_page) || 0}
          onChange={(event, value)=>{handlePageChange(event, value, showingAll)}}
          sx={{
            "& .MuiPaginationItem-circular.Mui-selected":{
              color: "#801F52",
              bgcolor: "#fff",
              border: "1px solid #801F52",
            },
            "& .MuiPaginationItem-circular.Mui-selected":{
              bgcolor: "#801F52",
              color: "#fff",
            }
          }}
        />


        {/* <table className="w-full lookupTypeTable">
          <thead className="tableHeader">
            <tr>
              <th style={{ width: "30px", minWidth: "30px" }}> </th>
              <th style={{paddingLeft: screenSize.width < 786 ? "630px" : "0px" }}>Employee Name</th>
              <th>Reporting To</th>
              <th>Leave Type</th>
              <th>From</th>
              <th>To</th>
              <th style={{ minWidth: "30px" }}>Days</th>
              <th style={{ minWidth: screenSize.width < 768 ?"30px" : "85px" }}>Status</th>
              <th
                style={{
                  width: "10px",
                  paddingRight: "10px",
                  minWidth: "3px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              ></th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {loading ? (
              <tr>
                {" "}
                <td className="text-center" colSpan={12}>
                  {" "}
                  Loading...{" "}
                </td>{" "}
              </tr>
            ) : tableData && tableData.length ? (
              tableData.map((item, index) => {
                return (
                  <tr className="tableBodyRow py-2" key={index}>
                    <td
                      className="p-0 padTop-0"
                      style={{
                        width: "30px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        minWidth: "30px",
                      }}
                    >
                      <Avatar
                        src={item.userData[0].imageURL}
                        alt={item.userData[0].displayName}
                        sx={{ height: "28px", width: "28px" }}
                      />
                    </td>
                    <td style={{paddingLeft: screenSize.width < 786 ? "630px" : "0px"}} >{TextField(item.userData[0].displayName)}</td>
                    <td>{TextField(item.manager)}</td>
                    <td>{TextField(item.leave_type)}</td>
                    <td>
                      {TextField(dayjs(item.from_date).format("DD-MMM-YYYY"))}
                    </td>
                    <td>
                      {TextField(dayjs(item.to_date).format("DD-MMM-YYYY"))}
                    </td>
                    <td style={{ minWidth: "30px" }}>{TextField(item.days)}</td>
                    <td className="" style={{ width: screenSize.width < 768 ? "30px" : "85px", padding: "0px" }}>
                      {TextStatusField(item.status)}
                    </td>
                    <td
                      style={{
                        paddingRight: "10px",
                        minWidth: screenSize.width < 768 ? "30px" : "85px",
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                      }}
                    >
                      {item.status != "Revoked" && item.status !== "Rejected" && !showingAll ? (
                        <IconButton
                          aria-label="Edit"
                          sx={{ p: "1px" }}
                          onClick={() => {
                            handleEditClick(item, showingAll);
                          }}
                        >
                          <img src={editIconNew} alt="Edit" />
                        </IconButton>
                      ) : null}
                      {showingAll &&
                      item.manager.toLowerCase() ===
                        userData.displayName.toLowerCase() ? (
                        <Button
                          sx={{
                            p: 0,
                            borderRadius: 2,
                            backgroundColor: "#FFF",
                            color: "#FF914E",
                            "& :hover": {
                              backgroundColor: "#FF914E",
                              color: "#FFF",
                            },
                          }}
                          onClick={() => {
                            handleEditClick(item, showingAll);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "11px",
                              px: "4px",
                              py: "7px",
                              borderRadius: 2,
                              fontWeight: 500,
                            }}
                          >
                            View Status
                          </Typography>
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center pt-6 font-bold">
                  {" "}
                  No Data Found{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
         */}
      </div>
    </div>
  );
}

export default LeavesDataTable;

const TextField = (value) => {
  return (
    <Typography
      paddingRight={2}
      // borderRight={1}
      sx={{
        typography: {
          fontSize: "14px",
          maxWidth: "305px",
          [theme.breakpoints.down("md")]: {
            fontSize: "0.7rem",
          },
        },
      }}
      // borderColor={"#94B1CB"}
      marginY={1}
      noWrap
    >
      {value}
    </Typography>
  );
};
const TextStatusField = (value) => {
  return (
    <Typography
      sx={{
        typography: {
          fontSize: "14px",
          // maxWidth: "325px",
          minWidth: "50px",
          width: "85px",
          fontWeight: 500,
          [theme.breakpoints.down("md")]: {
            fontSize: "0.7rem",
          },
        },
      }}
      marginY={1}
      noWrap
      color={
        value === "Pending"
          ? "#EE8B00"
          : value === "Approved"
          ? "#007C32"
          : value === "In-Draft"
          ? "#801F52"
          : "#FF3434"
      }
    >
      {value}
    </Typography>
  );
};
