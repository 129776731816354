import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import Slide from '@mui/material/Slide';

function SnackBarComponent( props ) {
let { open= true, message} = props;

function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <>
    <Snackbar
      anchorOrigin={{vertical:"top", horizontal: "right"}}
      open={open}
      key={"top" + "right"}
      autoHideDuration={3000}
      children={ <Alert severity="info" sx={{ width: '100%' }} icon={false}>
      {message}
    </Alert>}
    />
  </>
  );
}

export default SnackBarComponent;