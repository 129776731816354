import { useDispatch } from "react-redux";
import "./CheckIn.css";
import { useNavigate } from "react-router-dom";
import {
  DateCalendar,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  createTheme,
  TextField,
  IconButton
} from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import LocationApp from "../../shared/location";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { DatabaseService } from "../../../Services/DatabaseService";
import { useGeolocated } from "react-geolocated";
import { GeoAPIkey } from "../../../Constants/keys";
import CloseIcon from '@mui/icons-material/Close';
import { utils } from "../../../Common/Utills";

const theme = createTheme();

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

function CheckIn() {
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const { coords, getPosition, isGeolocationAvailable, isGeolocationEnabled,  } =
  useGeolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
    watchLocationPermissionChange: true,
  });

  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [finalAddress, setFinalAddress] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [today, setToday] = useState(dayjs(Date.now()));
  const [currentTime, setCurrentTime] = useState(dayjs(Date.now()));
  const [checkInTime, setCheckInTime] = useState("");
  const [checkInAddress, setCheckInAddress] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [checkOutLoading, setCheckOutLoading] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [checkInlimitTime, SetCheckInLimitTime] = useState('10:15');
  const [checkOutLimitTime, SetCheckOutLimitTime] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openCheckOutDialog, setOpenCheckOutDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Reason of Late Check-In');
  const [lateCheckInReason, setLateCheckInReason] = useState("");
  const [earlyCheckOutReason, setEarlyCheckOutReason] = useState("");
  const [lateCheckInReasonError, setLateCheckInReasonError] = useState(false);
  const [earlyCheckOutReasonError, setEarlyCheckOutReasonError] = useState(false);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setCurrentTime(dayjs(Date.now()));
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  useEffect(() => {
    getUserAttendance();
    getLookupCode();
  }, []);

  useEffect(()=>{
    console.log("coords----------------------->",coords, "isGeolocationEnabled------------------------>",isGeolocationEnabled);
    if(coords){
      // getAddressFromCoords()
      check_distance();
    }
  },[coords]);

  function haversine(lat1, lon1, lat2, lon2) {
    // Convert latitude and longitude from degrees to radians
    lat1 = toRadians(lat1);
    lon1 = toRadians(lon1);
    lat2 = toRadians(lat2);
    lon2 = toRadians(lon2);
  
    // Calculate differences
    var dlat = lat2 - lat1;
    var dlon = lon2 - lon1;
  
    // Haversine formula
    var a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    // Radius of Earth (mean radius in kilometers)
    var R = 6371.0;
  
    // Calculate distance
    var distance = R * c * 1000 ;
  
    return distance;
  }
  
  // Helper function to convert degrees to radians
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }
  
  const check_distance = async () => {
    
    let {latitude = "", longitude = ""} = coords;
    console.log("checking_distance");
    // Favcy Venture Builders office location
    var lat1 = 28.55390;
    var lon1 = 77.23138;
    
    // inby spaces by Favcy office location
    var lat2 = 28.56815;
    var lon2 = 77.21933;
    var distance1 = haversine(lat1, lon1, latitude, longitude);
    var distance2 = haversine(lat2, lon2, latitude, longitude);
    console.log("Distance: " + distance1.toFixed(2) + " m");
    console.log("Distance2: " + distance2.toFixed(2) + " m");

    if(distance1.toFixed(2) < 20 || distance2.toFixed(2) < 20){
      console.log("less then 10 meters");
      setFinalAddress("In-Office");
    }else{
      console.log("greater then 10 meters");
      setFinalAddress("Out-of-Office");
    }
    
  }

  const getUserAttendance = async () => {
    setIsLoading(true);
    let attendanceRes = await DatabaseService("GET", "attendance/logincheck");
    console.log("attendance_response---------------------->", attendanceRes);
    if (attendanceRes.code === 200) {
      let responseData = attendanceRes.data;
      setIsLoading(false);
      if (responseData) {
        setCheckInTime(dayjs(responseData.check_in_date));
        setCheckInAddress(responseData.check_in_address);
        if (responseData.check_out_date) {
          setCheckoutTime(dayjs(responseData.check_out_date));
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  const getLookupCode = async () => {
    setIsLoading(true);
    let lookupCodeRes = await DatabaseService("GET", "lookup/code_without_auth");
    console.log("lookup_response---------------------->", lookupCodeRes);
    if (lookupCodeRes.code === 200) {
      let responseData = lookupCodeRes.data;
      if(responseData. length){
        let filterCheckIN = responseData.find((lookupCode, index)=>{ return lookupCode.lookup_code_name.toLowerCase().includes("check in time") });
        if(filterCheckIN){
          SetCheckInLimitTime(filterCheckIN.lookup_code_description);
        }
        let filterCheckOut = responseData.find((lookupCode, index)=>{ return lookupCode.lookup_code_name.toLowerCase().includes("check out time") });
        console.log("checkout_limit_time---------->",filterCheckOut);
        if(filterCheckOut){
          SetCheckOutLimitTime(filterCheckOut.lookup_code_description);
        }
      }
      setIsLoading(false);
      
    } else {
      setIsLoading(false);
    }
  };

  const handleCheckIn = async () => {
    setCheckInLoading(true);
    console.log("location------------------>",coords);
    if (!coords) {
      alert(
        "Your location services are not enabled for this Application. Please enable them to proceed further."
      );
      getPosition();
      setCheckInLoading(false);
      return;
    }

    let checkINData = {
      check_in_address: finalAddress,
      check_in_date: new Date(currentTime.$d),
      check_in_lat: coords.latitude,
      check_in_lng: coords.longitude,
    };

     // Parse the time strings into Day.js objects
     const parsedTime1 = dayjs(checkInlimitTime, 'HH:mm');
     const parsedTime2 = dayjs(dayjs(currentTime.$d).format('HH:mm'), 'HH:mm');
 
     // Compare the parsed time objects
     if (parsedTime1.isAfter(parsedTime2)) {
         console.log(`${checkInlimitTime} is greater than ${dayjs(currentTime.$d).format('HH:mm')}`);
     } else if (parsedTime1.isBefore(parsedTime2) && lateCheckInReason.trim() === "" ) {
         console.log(`${checkInlimitTime} is less than ${dayjs(currentTime.$d).format('HH:mm')}`);
         setOpenDialog(true);
         setLateCheckInReason("");
         setLateCheckInReasonError(false);
         setDialogTitle("Reason for Late Check-In");
         setCheckInLoading(false);
         return
     }  else if (parsedTime1.isBefore(parsedTime2) && lateCheckInReason.trim() != "" ){
            checkINData.late_check_in_reason = lateCheckInReason;
     } else {
         console.log(`${checkInlimitTime} is equal to ${dayjs(currentTime.$d).format('HH:mm')}`);
     }


    let checkInResponse = await DatabaseService(
      "POST",
      "attendance",
      checkINData
    );
    if (checkInResponse.code === 200) {
      setOpenDialog(false);
      setLateCheckInReasonError(false);
      setLateCheckInReason("");
      setCheckInLoading(false);
      getUserAttendance();
    } else {
      setCheckInLoading(false);
    }
  };

  const getFinalAddress = async (address) => {
    let routeAddress = await address?.results?.find((address) =>
      address.types.includes("route")
    );

    let premiseAddress = await address?.results[0];
    let area_of_interestAddress = await address?.results[1];

    let finalRoute =
      (await routeAddress?.formatted_address?.split(",")[0].includes("+")) ||
      routeAddress?.formatted_address?.split(",")[0].includes("Unnamed Road")
        ? routeAddress?.formatted_address?.split(",")[1]
        : routeAddress?.formatted_address?.split(",")[0];

    let finalPremise =
      (await premiseAddress?.formatted_address?.split(",")[0].includes("+")) ||
      premiseAddress?.formatted_address?.split(",")[0].includes("Unnamed Road")
        ? premiseAddress?.formatted_address?.split(",")[1]
        : premiseAddress?.formatted_address?.split(",")[0];

    let finalFirstIndex =
      (await area_of_interestAddress?.formatted_address
        ?.split(",")[0]
        .includes("+")) ||
      area_of_interestAddress?.formatted_address
        ?.split(",")[0]
        .includes("Unnamed Road")
        ? area_of_interestAddress?.formatted_address?.split(",")[1]
        : area_of_interestAddress?.formatted_address?.split(",")[0];

    if (routeAddress?.formatted_address === premiseAddress?.formatted_address) {
      let finalAdd = finalFirstIndex + ", " + finalRoute;
      setFinalAddress(finalAdd);
    } else {
      let finalAdd = finalPremise + ", " + finalRoute;
      setFinalAddress(finalAdd);
    }
  };

  const OnCheckOutClick = () => {
    if (window.confirm("Are you sure you want to check out?")) {
      // Save it!

      if(checkOutLimitTime){
        // Parse the time strings into Day.js objects
        const parsedTime1 = dayjs(checkOutLimitTime, 'HH:mm');
        const parsedTime2 = dayjs(dayjs(currentTime.$d).format('HH:mm'), 'HH:mm');

        // Compare the parsed time objects
        if (parsedTime1.isAfter(parsedTime2)) {
          console.log(`${checkOutLimitTime} is greater than ${dayjs(currentTime.$d).format('HH:mm')}`);
          setOpenCheckOutDialog(true);
          setEarlyCheckOutReason("");
          setEarlyCheckOutReasonError(false);
          setDialogTitle("Reason for Early Check-Out");
          setCheckOutLoading(false);
          return
        } else if (parsedTime1.isBefore(parsedTime2) ) {
          console.log(`${checkOutLimitTime} is less than ${dayjs(currentTime.$d).format('HH:mm')}`);
          handleCheckOut();
        } else {
          handleCheckOut();
          console.log(`${checkOutLimitTime} is equal to ${dayjs(currentTime.$d).format('HH:mm')}`);
        }
      }else{
        handleCheckOut();
      }

    } else {
      // Do nothing!
      return;
    }
  };

  const handleCheckOut = async () => {
    setCheckOutLoading(true);
    if (!coords) {
      alert(
        "Your location services are not enabled for this Application. Please enable them to proceed further."
      );
      getPosition();
      setCheckOutLoading(false);
      return;
    }

    let checkOutData = {
      check_out_address: finalAddress,
      check_out_date: new Date(currentTime.$d),
      check_out_lat: coords.latitude,
      check_out_lng: coords.longitude,
      early_check_out_reason : earlyCheckOutReason.toString().trim()
    };

    let checkOutResponse = await DatabaseService(
      "POST",
      "attendance",
      checkOutData
    );
    if (checkOutResponse.code === 200) {
      getUserAttendance();
      setOpenCheckOutDialog(false);
      setCheckOutLoading(false);
    } else {
      setCheckOutLoading(false);
    }
  };

  const validateReason = async () => {
    if(lateCheckInReason.toString().trim() === ""){
      setLateCheckInReasonError(true);
    }else{
      handleCheckIn()
    }
  }

  const validateCheckoutReason = async () => {
    if(earlyCheckOutReason.toString().trim() === ""){
      setEarlyCheckOutReasonError(true);
    }else{
      handleCheckOut()
    }
  }

  const fetchLocation = async () => {
    if(screenSize.width < 768){
      alert("Your location permission will be required for which you will have to enable GPS of your phone.");
    }else{
      alert("Please try to grant location permission by clicking on Allow button or by clicking on the lock icon on the left side of the url bar and then enabling the location.");
    }
    getPosition()
  }

  const checkNavigation = async () =>{
    let lastLocation = await utils.getData("lastPage");
    if(lastLocation){
      localStorage.removeItem("lastPage");
      navigate(lastLocation);
    }else{
      navigate("/dashboard");
    }
  }

  return (
    <>
      <div className="card flex flex-col justify-around">
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
            Loading...
          </Box>
        ) : (
          <>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  disablePast
                  disableFuture
                  defaultValue={today}
                  sx={{
                    boxShadow: "4px 6px 20px 5px #0000001A",
                    borderRadius: "16px",
                    width: "404px",
                    ".MuiPickersCalendarHeader-root": {
                      backgroundColor: "#FF914E",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      padding: "25px",
                      marginTop: "0px",
                    },
                    ".MuiPickersCalendarHeader-label": {
                      textAlign: "center",
                      color: "#fff",
                    },

                    ".MuiPickersArrowSwitcher-button": {
                      paddingRight: "7px",
                      color: "#fff !important",
                    },
                    ".MuiPickersCalendarHeader-switchViewIcon": {
                      color: "#fff !important",
                    },
                    ".MuiDayCalendar-weekDayLabel": {
                      height: "42px",
                      width: "45px",
                      color: "#595959",
                      fontWeight: 600,
                    },
                    ".MuiPickersDay-dayWithMargin": {
                      height: "42px",
                      width: "45px",
                    },
                    "& .MuiPickersDay-root": {
                      "&.Mui-selected": {
                        backgroundColor: "#FF914E",
                        height: "34px",
                        width: "40px",
                        borderRadius: "5px",
                      }
                    },
                    [theme.breakpoints.down("md")]: {
                      height: "330px",
                      width: "340px",
                      ".MuiPickersCalendarHeader-root": {
                        padding: "20px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box className="flex justify-evenly mt-3 mx-0 md:mt-5">
              <Box className="flex justify-start flex-col items-center">
                <Box>
                  <Typography sx={styles.headerTypography}>
                    <LocationOnIcon
                      sx={{
                        fontSize: "16px",
                        [theme.breakpoints.down("md")]: { fontSize: "14px" },
                      }}
                    />
                    Locate me
                  </Typography>
                </Box>
                <Box>
                  {
                  ( !isGeolocationEnabled ?
                    <Button
                      onClick={() => {
                        fetchLocation();
                      }}
                      disabled={
                        ( !isGeolocationEnabled && !coords && checkInTime !== "" && checkoutTime === "") ||
                        checkoutTime !== ""
                      }
                      sx={{
                        ...styles.BTNStyle,
                        width: "140px",
                        [theme.breakpoints.down("md")]: {
                          width: "103px",
                          "&:hover": {
                            bgcolor: "#FFF",
                            transform: "scale(1)",
                          },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          ...styles.BTNText,
                          [theme.breakpoints.down("md")]: { fontSize: "11px" },
                        }}
                      >
                        Fetch Location
                      </Typography>
                    </Button>
                    : 
                  coords && finalAddress ? (
                    <LocationApp location={coords} address={finalAddress} />
                  ) : null)}
                </Box>
              </Box>
              <Box className="flex justify-start flex-col items-center">
                <Box>
                  <Typography sx={styles.headerTypography}>
                    {dayjs(checkInTime || currentTime).format("LT")}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      handleCheckIn();
                    }}
                    disabled={
                      (checkInTime !== "" && checkoutTime === "") ||
                      checkoutTime !== ""
                    }
                    sx={styles.BTNStyle}
                  >
                    {checkInLoading ? (
                      <CircularProgress
                        sx={{ color: "#801F52" }}
                        size={"20px"}
                      />
                    ) : (
                      <Typography sx={styles.BTNText}>Check In</Typography>
                    )}
                  </Button>
                </Box>
              </Box>
              <Box className="flex justify-start flex-col items-center">
                <Box>
                  <Typography sx={styles.headerTypography}>
                    {checkInTime === ""
                      ? "--:-- --"
                      : dayjs(checkoutTime || currentTime).format("LT")}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    sx={styles.BTNCheckoutStyle}
                    disabled={checkInTime === "" || checkoutTime !== ""}
                    onClick={() => {
                      OnCheckOutClick();
                    }}
                  >
                    {checkOutLoading ? (
                      <CircularProgress
                        sx={{ color: "#801F52" }}
                        size={"20px"}
                      />
                    ) : (
                      <Typography sx={styles.BTNText}>Check Out</Typography>
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className=" md:mx-6">
              <Button
                // disabled={checkInTime === "" || checkoutTime !== ""}
                sx={{ width: "100%", ...styles.BTNFullStyle }}
                onClick={() => {
                  checkNavigation();
                }}
              >
                Proceed To Dashboard
              </Button>
            </Box>
          </>
        )}
      </div>
      <Dialog
      sx={{ '& .MuiDialog-paper': { width: '85%', maxHeight: 435, background: '#838383', borderRadius: '24px' } }}
      maxWidth="xs"
      open={openDialog}
      >
        <DialogTitle sx={{color: '#fff', fontSize: '20px', fontWeight: 600}} > {dialogTitle} </DialogTitle>
        <Box className="bg-white mx-4 mb-4 rounded-md">
        <DialogContent sx={{p: "10px"}}>
        <Box className="w-full bg-white rounded-md">
          <FormControl error={lateCheckInReasonError} variant="standard" fullWidth>
            <textarea className="w-full rounded-md" placeholder="Please share your reason" rows={4} 
              value={lateCheckInReason}
              onInput={(e)=>{
                setLateCheckInReason(e.target.value);
              }}
              style={{'padding': '10px', color: '#000', outline: 'none', fontSize: '14px'}} />
            {
              lateCheckInReasonError && lateCheckInReason.trim().length < 1 ? 
                <FormHelperText id="component-error-text">Required</FormHelperText>
              :null
            }
          </FormControl>
        </Box>
        </DialogContent>
        <DialogActions sx={{pt:0}}>
          <Button disabled={checkInLoading} sx={{background: "#801F52", color: "#fff", height: "22px", fontSize: '11px', fontWeight: 600, "&:hover":{ background: "#801F52", color: "#fff", transform: "scale(1.1)" }}} onClick={()=>{validateReason()}}>
            {
              checkInLoading ? 
                  <CircularProgress
                    sx={{ color: "#FFF" }}
                    size={"20px"}
                  />
              : 'Submit'
            }
            
          </Button>
        </DialogActions>
        </Box>
        <IconButton aria-label="delete" className="right-2 top-3" sx={{ position: 'absolute' }} onClick={()=>{setOpenDialog(false);setLateCheckInReason("");setLateCheckInReasonError(false);}}>
            <CloseIcon className="text-white" />
          </IconButton>
    </Dialog>
      <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435, background: '#838383', borderRadius: '24px' } }}
      maxWidth="xs"
      open={openCheckOutDialog}
      >
        <DialogTitle sx={{color: '#fff',fontSize: '20px', fontWeight: 600}}> {dialogTitle} </DialogTitle>
        <Box className="bg-white mx-4 mb-4 rounded-md">
        <DialogContent sx={{p: "10px"}}>
          <FormControl error={earlyCheckOutReasonError} variant="standard" fullWidth>
            <textarea className="w-full rounded-md" placeholder="Please enter reason" rows={4} 
              value={earlyCheckOutReason}
              onInput={(e)=>{
                setEarlyCheckOutReason(e.target.value)
              }}
              style={{'padding': '10px', color: '#000', outline: 'none', fontSize: '14px'}} />
            {
              earlyCheckOutReasonError && earlyCheckOutReason.trim().length < 1 ? 
                <FormHelperText id="component-error-text">Required</FormHelperText>
              :null
            }
          </FormControl>
        </DialogContent>
        <DialogActions sx={{pt:0}}>
          <Button disabled={checkOutLoading} sx={{background: "#801F52", color: "#fff", height: "22px", fontSize: '11px', fontWeight: 600, "&:hover":{ background: "#801F52", color: "#fff", transform: "scale(1.1)" }}} onClick={()=>{validateCheckoutReason()}}>
            {
              checkOutLoading ? 
                  <CircularProgress
                    sx={{ color: "#FFF" }}
                    size={"20px"}
                  />
              : 'Submit'
            }
            
          </Button>
        </DialogActions>
        </Box>
        <IconButton aria-label="delete" className="right-2 top-3" sx={{ position: 'absolute' }} onClick={()=>{setOpenCheckOutDialog(false)}}>
            <CloseIcon className="text-white" />
          </IconButton>
    </Dialog>
    </>
  );
}

export default CheckIn;

export const styles = {
  headerTypography: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24.7px",
    color: "#801F52",
    height: "34px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "17.29px",
      height: "26px",
    },
  },
  BTNStyle: {
    fontWeight: 600,
    py: 1,
    fontSize: "16px",
    border: "1.5px solid #FF914E",
    borderRadius: "36px",
    color: "#FF914E",
    width: "120px",
    "&:hover": {
      bgcolor: "#FFF",
      transform: "scale(1.07)",
    },
    "&:disabled": {
      bgcolor: "#C08FA9",
      // opacity: "0.7",
      fontSize: "16px",
      color: "#F3F9FF",
      fontWeight: 600,
      border: "1.5px solid #C08FA9",
    },
    [theme.breakpoints.down("md")]: {
      width: "103px",
      fontSize: "14px",
      lineHeight: "17.29px",
      "&:hover": {
        bgcolor: "#F3F9FF",
        transform: "scale(1.0)",
      },
    },
  },
  BTNCheckoutStyle: {
    fontWeight: 600,
    py: 1,
    fontSize: "16px",
    border: "1.5px solid #FF914E",
    borderRadius: "36px",
    color: "#FF914E",
    width: "120px",
    "&:hover": {
      bgcolor: "#FFF",
      transform: "scale(1.07)",
    },
    "&:disabled": {
      bgcolor: "#FFF",
      // opacity: "0.7",
      fontSize: "16px",
      color: "#BFBFBF",
      fontWeight: 600,
      border: "1.5px solid #BFBFBF",
    },
    [theme.breakpoints.down("md")]: {
      width: "103px",
      fontSize: "14px",
      lineHeight: "17.29px",
      "&:hover": {
        bgcolor: "#F3F9FF",
        transform: "scale(1.0)",
      },
    },
  },
  BTNText: {
    fontWeight: 600,
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  BTNFullStyle: {
    fontWeight: 600,
    px: 3,
    py: 1,
    fontSize: "16px",
    bgcolor: "#801F52",
    color: "#F3F9FF",
    borderRadius: "16px",
    "&:hover": {
      border: "1.5px solid #801F52",
      color: "#801F52",
    },
    "&:disabled": {
      bgcolor: "#FFF",
      fontSize: "16px",
      // opacity: "0.7",
      color: "#BFBFBF",
      fontWeight: 600,
      border: "1.5px solid #BFBFBF",
    },
    [theme.breakpoints.down("md")]: {
      mt: 1,
      px: 1,
      typography: {
        fontSize: "14px",
      },
    },
  },
};