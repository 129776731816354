import React, { useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

export default function CustomTimePicker({ label, index, handleTimeChange, value }) {
  const [time, setTime] = useState(value);
  const [open, setOpen] = useState(false);

  const handleFieldClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePickerTimeChange = (newTime) => {
    setTime(newTime);
    setOpen(false);
    handleTimeChange(index, newTime);
  };

  return (
    <Box>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker 
                slotProps={{ textField: { size: 'small', }, field: { placeholder: "Check Out Time" } }}
                value={ time ? dayjs(time) : ""}
                onChange={(newValue) => handlePickerTimeChange(newValue)} 
                sx={{
                ".MuiStack-root":{
                    paddingTop: '0px',
                    overflowY: 'hidden',
                },
                ".MuiOutlinedInput-notchedOutline":{
                    border: 'none',
                },
                ".MuiInputBase-inputAdornedEnd":{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    paddingLeft: "0px",
                    maxWidth: '100px',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#221F4B',
                }
                }}
                />
        </LocalizationProvider>
    </Box>
  );
}
